<template scoped>
  <div
    :style="{ padding: '10px', height: 'calc(100% - 20px)', overflowY: 'auto' }"
  >
    <el-row>
      <el-col>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span style="font-size: x-large">{{ title }}</span>
            <el-tooltip
              class="item"
              effect="dark"
              content="Refresh"
              placement="top"
            >
              <el-button
                @click="refresh"
                :style="{ float: 'right', marginLeft: '10px' }"
                icon="el-icon-refresh"
                circle
              ></el-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              :content="tooltip_new"
              placement="top"
            >
              <el-button
                @click="create"
                style="float: right"
                icon="el-icon-plus"
                circle
              ></el-button>
            </el-tooltip>
          </div>
          <div class="text item">
            <el-row :style="{ marginBottom: '10px' }">
              <el-col :style="{ marginBottom: '10px' }"> Filter: </el-col>
              <el-col>
                <el-tag
                  closable
                  :style="{ marginRight: '10px' }"
                  type="warning"
                  v-for="item in filter"
                  :key="item.name"
                >
                  {{ item.name }} : {{ item.friendlyValue }}
                </el-tag>

                <el-input
                  v-for="item in filter"
                  :key="item.id"
                  placeholder="Value"
                  v-model="item.value"
                  class="input-with-select"
                >
                  <el-select
                    :style="{ width: '300px' }"
                    v-model="item.id"
                    slot="prepend"
                    placeholder="Select"
                  >
                    <el-option
                      v-if="filterItem in available_filter"
                      :key="filterItem.id"
                      :label="filterItem.displayName"
                      :value="filterItem.id"
                    ></el-option>
                  </el-select>
                  <el-button slot="append" icon="el-icon-plus"
                    >New Filter</el-button
                  >
                </el-input>
              </el-col>
            </el-row>
            <el-alert
              v-if="error.show"
              :title="error.title"
              type="error"
              :description="error.message"
              show-icon
              :closable="false"
            >
            </el-alert>
            <el-table
              v-if="!error.show"
              v-loading="paging.loading"
              :data="items"
              style="width: 100%"
            >
              <slot></slot>
            </el-table>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :style="{ marginTop: '10px' }">
      <el-col>
        <el-pagination
          v-if="!error.show"
          background
          @size-change="pagingSizeChange"
          @current-change="pagingPageChange"
          :current-page="paging.currentPage"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="paging.totalItem"
        >
        </el-pagination>
      </el-col>
    </el-row>
  </div>
</template>

<style>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>

<script>
import copy from "copy-to-clipboard";

export default {
  props: {
    endpoint: String,
    toPage: Function,
    resourceServer: String,
    resourceId: String,
    title: String,
    create: Function,
    resourceServerApi: Boolean,
    apiPath: String,
    parameter: Object,
  },
  data: function () {
    return {
      filter: [],
      available_filter: [
        {
          id: "name",
          displayName: "Name",
          type: "String",
        },
      ],
      items: [],
      error: {
        show: false,
        title: "",
        message: "",
      },
      paging: {
        loading: true,
        currentPage: 1,
        pageSize: 10,
        totalItem: 0,
      },
    };
  },
  created: function () {
    this.filter = this.getFilter();
    this.filter = [
      {
        id: "manufactoryId",
        name: "ManufactoryId",
        value: "L_rYARDkb0ai47g-rpTb4A",
        friendlyValue: "Applied Technology Group Limited",
      },
    ];
    this.refresh();
  },
  computed: {
    tooltip_new: function () {
      return `New ${this.title}`;
    },
  },
  methods: {
    getFilter: function () {
      if (this.parameter) {
        if (this.parameter.filter) {
          return this.parameter.filter;
        }
      }
      return [];
    },
    buildFilterQueryString: function () {
      var result = "";
      for (var item in this.filter) {
        var data = this.filter[item];
        result += `&${data.id}=${data.value}`;
      }
      return result;
    },
    pagingSizeChange: function (val) {
      this.paging.pageSize = val;
      this.refresh();
    },
    pagingPageChange: function (val) {
      this.paging.currentPage = val;
      this.refresh();
    },
    refresh: function () {
      var $this = this;
      $this.paging.loading = true;

      var url = "";
      var queryString = this.buildFilterQueryString();

      if (this.resourceServerApi) {
        url = `${this.resourceServer}/api/Frontend/${this.apiPath}/${this.resourceId}/?Page=${this.paging.currentPage}&PageSize=${this.paging.pageSize}${queryString}`;
      } else {
        url = `${this.endpoint}/api/${this.apiPath}?Page=${this.paging.currentPage}&PageSize=${this.paging.pageSize}${queryString}`;
      }

      this.$plugin.request
        .get(url)
        .then(function (data) {
          $this.items = data.result;
          $this.paging.totalItem = data.totalItems;
          $this.paging.pageSize = data.pageSize;
          $this.paging.loading = false;
        })
        .catch(function (error) {
          $this.error.show = true;
          if (error.statusCode == "TypeError: Failed to fetch") {
            $this.error.title = "Cross-Origin Resource Sharing (CORS)";
            $this.error.message = "Forbidden: Domain not whitelisted";
          } else {
            $this.error.title = `HTTP ${error.statusCode}`;
            $this.error.message = `Forbidden: ${error.errorMessage}`;
          }

          console.log(error);
        });
    },
    remove: function (item) {
      var $this = this;
      this.$confirm(
        "This will permanently delete the item. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        var url = "";
        if (this.resourceServerApi) {
          url = `${$this.resourceServer}/api/Frontend/${$this.apiPath}/${$this.resourceId}/${item.id}`;
        } else {
          url = `${$this.endpoint}/api/${$this.apiPath}/${item.id}`;
        }

        this.$plugin.request.delete(url).then(function () {
          $this.refresh();
        });
      });
    },
    copyId: function (item) {
      copy(item.id);
    },
    setEnable: function (item) {
      var url = "";

      if (item.enable) {
        if (this.resourceServerApi) {
          url = `${this.resourceServer}/api/Frontend/${this.apiPath}/${this.resourceId}/${item.id}/Enable`;
        } else {
          url = `${this.endpoint}/api/${this.apiPath}/${item.id}/Enable`;
        }

        this.$plugin.request.put(url).then(function () {});

        // fetch(url, {
        //   method: "PUT",
        //   headers: {
        //     Authorization: `${this.$plugin.authorization.info.token_type} ${this.$plugin.authorization.info.access_token}`,
        //   },
        // })
        //   .then(function (request) {
        //     if (!request.ok) {
        //       throw new Error(request.status);
        //     }
        //     return request.json();
        //   })
        //   .then(function () {})
        //   .catch(function (error) {
        //     $this.error.show = true;
        //     if (error == "Error: 403") {
        //       $this.error.title = "HTTP 403.6";
        //       $this.error.message = "Forbidden: IP address rejected";
        //     } else if (error == "Error: 401") {
        //       $this.error.title = "HTTP 401";
        //       $this.error.message = "Forbidden: Unauthorized";
        //     } else if (error == "TypeError: Failed to fetch") {
        //       $this.error.title = "Cross-Origin Resource Sharing (CORS)";
        //       $this.error.message = "Forbidden: Domain not whitelisted";
        //     } else {
        //       console.log(error);
        //     }
        //   });
      } else {
        if (this.resourceServerApi) {
          url = `${this.resourceServer}/api/Frontend/${this.apiPath}/${this.resourceId}/${item.id}/Disable`;
        } else {
          url = `${this.endpoint}/api/${this.apiPath}/${item.id}/Disable`;
        }

        this.$plugin.request.put(url).then(function () {});
      }
    },
  },
};
</script>